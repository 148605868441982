<template>
<div id="per_center">
    <div class="main">
      <div class="main_top">
        <div class="main_top_wrapper">
          <img src="@/assets/image/HelpCenter/help_title.png">
          <div>
            <el-input
              placeholder="请输入问题关键词"
              clearable
              @keydown.enter.native="handleSearch"
              v-model.trim="query.matchCon">
              <span slot="prefix">
                <img src="@/assets/image/HelpCenter/search.png">
              </span>
            </el-input>
          </div>
        </div>
      </div>
      <div class="main_content">
        <!-- <router-view></router-view> -->
        <div class="hellp_search">
          <div class="search_wrapper">
            <div class="search_top">
              <div class="top_left">
                <router-link :to="{name:'helpCenter'}">返回</router-link>
                /
                <span>搜索结果</span>
              </div>
              <!-- <div class="top_right">找不到答案？<a>点击联系在线客服<i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i></a></div> -->
            </div>
            <div class="search_list">
              <div class="search_list_item" v-for="item in helpList" :key="item.id">
                <router-link tag="a" target="_blank" class="item_title" :to="{name: 'helpDetail',query: {id: item.id,cat_id: item.cat_id}}"><span v-html="item.title"></span> <i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i></router-link>
                <div class="item_path">全部问题 / {{ item.cat_name }}</div>
                <div class="item_con" v-html="item.description"></div>
              </div>
            </div>
          </div>
          <div class="contract-pages" v-if="totalCount > query.pageSize">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="query.page"
              :page-size="query.pageSize"
              layout="prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { apiHelpCenter, apiHelpDetail, apiHelpSearch } from "@/request/API";
export default {
  name: 'HelpSearch',
  data() {
    return {
      query: {
        matchCon: '',
         // 默认显示第几页
        page: 1,
        // 默认每页显示的条数
        pageSize: 10,
      },
      // 总条数，根据接口获取数据长度
      totalCount: 0,
      helpList: []
    }
  },
  created() {
    this.query.matchCon = this.$route.query.keyWord
    this.getHelpData()
  },
  mounted() {
    // this.$on('getSearch',(val) => {
    //   this.query.matchCon
    //   this.getHelpData()
    // })
  },
  methods: {
    handleSearch() {
      this.getHelpData()
    },
    handleCurrentChange(val) {
      this.query.page = val
      this.getHelpData()
    },
    getHelpData() {
      apiHelpSearch(this.query).then(res => {
        this.helpList = res.data.list
        this.totalCount = res.data.total
      })
    }
  },
}
</script>

<style lang="less" scoped>
#per_center {
  width: 1200px;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  min-height: 644px;

  .main {
    width: 1200px;
    //height: 727px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    // position: relative;
    .main_top{
      height: 230px;
      background: url("../../assets/image/HelpCenter/bg_top.png") no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .main_top_wrapper{
        width: 450px;
        text-align: center;
        padding-top: 41px;
        >img{
          width: 257px;
          height: 31px;
          margin: 0 0 22px;
        }
        /deep/input{
          height: 50px;
          border-radius: 23px;
          padding-left: 55px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          border: none;
          // color: #cacdd6;
        }
        /deep/.el-input__prefix{
          width: 18px;
          height: 18px;
          top: calc(50% - 9px);
          left: 26px;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .main_content{
      position: relative;
      top: -67px;
      // top: 174px;
      left: 82px;
      width: 1036px;
    }
  }
}
.hellp_search{
  .search_wrapper{
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    min-height: 465px;
    padding: 30px;
    .search_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      line-height: 24px;
      color: #ADC0FB;
      font-size: 16px;
      margin-bottom: 10px;
      .top_left{
        a{
          display: inline-block;
          width: 55px;
          background: #adc0fb;
          border-radius: 16px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          margin-right: 10px;
        }
        span{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #35477c;
          margin-left: 10px;
        }
      }
      .top_right{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #000000;
        a{
          color: #FF8B08;
          cursor: pointer;
          i{
            width: 6px;
          }
        }
      }
    }
    .search_list{
      .search_list_item{
        padding: 15.5px 20px;
        .item_title{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
          cursor: pointer;
          i{
            width: 8px;
          }
        }
        .item_path{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #adb0b9;
          margin: 6px 0;
        }
        .item_con{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #000000;
          position: relative; line-height: 20px;
          max-height: 44px;
          line-height: 22px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &:nth-child(2n){
          border-top: 1px solid #eef0f6;
          border-bottom: 1px solid #eef0f6;
        }
      }
    }
  }
}
</style>
<style lang="less">
  .hellp_search{
  .contract-pages {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .el-pager {
      li.active {
        color: #fcad13;
      }
      li:hover {
        color: #fcad13;
      }
    }
    .el-pagination {
      button:hover {
        color: #fcad13;
      }
    }
  }
}
</style>
